import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HistoryRecap from './HistoryRecap';


export default function About() {
  
  return (
    <div id="about">
      <div id = "abt"className='aboutSlide'>

      </div>
      <div className="mt-4 mx-4">
        <div className="row">
          <div className="col-md-8">
            <h1>हमारे बारे में</h1>
            <p className="lead fw-normal pr-5">
              उत्तराखंड सांस्कृतिक एकता मंच (पंजीकृत 2947) प्रवासी उत्तराखंडवासियों द्वारा स्थापित एक सामाजिक एवं सांस्कृतिक संगठन है।
            </p>
            <p>
              इसमें समाज एवं मेहनतकश लोगों की सहमति से 2017 में इस सभा का गठन किया गया, जो समाज के विभिन्न क्षेत्रों के असीम प्रेम, सहयोग हेतु किया गया।
              अथक प्रयास और मेहनत का जीता जागता उदाहरण, अनेकता में एकता को दर्शाता है।
            </p>
            <p>
              इस संस्था को सुचारू और सुव्यवस्थित तरीके से चलाने के लिए, निम्नलिखित निकाय सदस्यों का गठन किया गया और सोसायटी कानून के अनुसार 3 साल की अवधि के लिए चुना गया,
              पहले निर्वाचित निकाय सदस्यों को महामारी COVID19 के कारण 6 साल के लिए बढ़ा दिया गया था।
            </p>
            <p>
              शुरुआत में निर्वाचित सदस्यों और उत्तराखंड निवासियों के साथ हर साल माता का जागरण आयोजित किया जाता था,
              साथ ही रक्तदान शिविर, स्वच्छता अभियान, चिकित्सा शिविर जैसी सामाजिक गतिविधियों के साथ जरूरतमंदों को आर्थिक और सामाजिक रूप से मदद की जाती थी।
            </p>
            <p>
              संगठन के कार्यप्रवाह को जारी रखने के लिए फरवरी 2023 में नए निकाय सदस्यों का चुनाव किया गया, जिन्होंने 6 महीने के भीतर विभिन्न गतिविधियाँ हासिल कीं और कीं।
            </p>
          </div>
          <div className="col-md-4 bg-success text-white p-2 rounded">
            <h3>हमारे लक्ष्य</h3>
            <div style={{ height: '299px', overflowY: 'scroll' }}>
              <ul className="list-group">
                <li className="list-group-item">उत्तराखण्ड प्रवासी समाज को समुचित ढंग से एकत्रित करना ।</li>
                <li className="list-group-item">भारतीय संविधान के अनुरूप उत्तराखण्डी प्रवासी समाज का विकास । </li>
                <li className="list-group-item">उत्तराखण्ड संस्कृति को बनायें रखना ।</li>
                <li className="list-group-item">संस्था द्वारा विभिन्न संस्थानों एंव समाज सेवियों से परस्पर सम्बन्ध बनाना ।</li>
                <li className="list-group-item">उत्तराखण्डी समाज के ऐसें छात्रों को प्रोत्साहित करना जो विद्यालयी शिक्षा और कला में आगे हों ।</li>
                <li className="list-group-item">सभी प्रवासी भाई-बहिनों के द्वारा आपसी भाईचारे को बढावा देना ।</li>
                <li className="list-group-item">मेधावी छात्रों के लिए प्रतिस्पर्धा कार्यक्रमों का आयोजन करना जिससे उनका बहुमुखी विकास हों ।</li>
                <li className="list-group-item">संस्था का उद्वेश्य सभी प्रवासी भाई-बहिनों के द्वारा आपसी भाईचारे को बढावा देना । </li>
                <li className="list-group-item">उत्तराखण्ड में होने वाली विभिन्न प्राकृतिक आपदाओं में सहायता हेतु अपनी पूर्ण भागीदारी निभाना ।</li>
                <li className="list-group-item">स्वास्थ्य लाभ और शारीरिक उर्जा बनाये रखने के लिए मंच के माध्यम से आयोजन करना ।</li>
              </ul>
            </div>

          </div>
        </div>

        <div className="row mt-3 sky-blue-background p-4">
          <div className="col-md-6">
            <h3>कार्य समिति सदस्य (2023-26)</h3>
            <p>
              अध्यक्ष        : मनोज कुमार
              <br />
              उपाध्यक्ष 		  : सुभाष बुड़ाकोटी
              <br />
              महासचिव		  : रमिंदर सिंह नेगी
              <br />
              सचिव			  : केशव डोबरियाल
              <br />
              कोषाध्यक्ष		  : रमेश घिल्डियाल, सनत डोबरियाल
              <br />
              सांस्कृतिक सचिव		  : जग मोहन सिंह रावत
              <br />
              प्रवक्ता एवं प्रचार सचिव	  : युद्धवीर बिष्ट
              <br />
              संगठन सचिव : संदीप रावत, मनमोहन रावत
              <br />
              प्रचार सचिव एवंअकाउंटेंट  : बीरेंद्र रावत
              <br />
              मीडिया प्रभारी		  : सुनील रावत
              <br />
              सलाहकार		  : मेहरबान सिंह रौथाण, हयात सिंह राणा
              <br />
              माल सचिव		  : रवि बर्थवाल
              <br />
              उपाध्यक्ष (महिला)	  : हेमलता मिश्रा
              <br />
              उप सचिव (महिला)	  : कुसुम जोशी
              <br />
              प्रचार सचिव (महिला)	  : विनीता गोसाईं
              <br />


            </p>
            <br />
          </div>


          <div className="col-md-6 sky-blue-background p-4">
            <h3>कार्य समिति सदस्य (2017-23)</h3>
            <p>
              अध्यक्ष   		:पुष्कर सिंह बिष्ट
              <br />
              उपाध्यक्ष 		: सुभाष बुड़ाकोटी
              <br />
              महासचिव		: अनिल प्रकाश मिश्रा
              <br />
              सचिव एवं प्रवक्ता		: मनोज भंडारी
              <br />
              कोषाध्यक्ष		: रमिंदर सिंह नेगी
              <br />
              सांस्कृतिक सचिव		: जग मोहन सिंह रावत
              <br />
              प्रचार मंत्री		: युद्धवीर बिष्ट
              <br />
              प्रचार सचिव		: बीरेंद्र रावत
              <br />
              संगठन सचिव		: गणेश नेगी
              <br />
              मीडिया प्रभारी 		: विक्रम बिष्ट
              <br />
              सलाहकार		: देवेन्द्र भट्ट, केशव डोबरियाल
            </p>
          </div>
          <p>
            संपर्क करें:
            पंजीकृत कार्यालय: ए/18, लक्ष्मी गार्डन, पुलिस चौकी के पास, खांडसा रोड, गुरुग्राम (हरियाणा)
            # 9999407619, 9958513832
          </p>
        </div>
        <HistoryRecap/>
      </div>
    </div>
  )
}
