import React from 'react';
// import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap'; 

const imageSize = {
  maxWidth: '100%',
  height: 'auto',
};

export default function Events() {
  return (
    
      <>
      
      <div className='mx-5'>
        <div className="row">
            {/* Left side */}
            <div className="col-md-6 p-3">
            <img
                className="d-block w-100 text-white"
                // eslint-disable-next-line
                src="assets\event4\1 (3).jpg"
                alt="First slide" />
            </div>
            {/* right side */}
            <div className="col-md-6 p-3">
                <h2>मकरेणी कौथिक महोत्सव (जनवरी, 2024) </h2>
                <p>
                उत्तराखंड सांस्कृतिक एकता मंच ने गुरुग्राम में आयोजित हुए मकरेणी कौथिक महोत्सव के सुखद पलों को साझा करते हुए समारोह को सम्पन्न किया। यह समारोह ने उत्तराखंड के समृद्ध सांस्कृतिक विरासत को बढ़ावा देने में अद्वितीय योगदान किया और स्थानीय जन समाज को एक साथ लाने में सफल रहा। 
                <br/>
                <br/>
                मकरेणी कौथिक महोत्सव में मुख्य अतिथियों के रूप में राकेश दौलताबाद और जी एल शर्मा ने समारोह को विशेष रूप से सजग किया। इसके साथ ही, चंद्र दत्त जोशी, समाजसेवी और मदन मोहन बिष्ट, एक कुशल व्यापारी, भी उपस्थित रहे और छात्रों को सम्मानित करने में भागीदार रहे।
                <br/>
                <br/>
                लोक गायिका हेमा नेगी करासी, मुकेश कठैत, मनोज आर्य, और जगमोहन रावत चौंदकोटी जी ने अपने सूरों से सभी को मोहित किया और समारोह को रौंगतें भर दीं। साथ ही, हास्य कलाकार संदीप छिलबट ने हंसी की धारा को बहुत अच्छे से बहाया और सभी को हंसी के मैदान में ले गए।
                <br/>
                <br/>
                उत्तराखंड सांस्कृतिक एकता मंच की ओर से आयोजित मकरेणी कौथिक महोत्सव ने गुरुग्राम में रह रहे उत्तराखंड जन समाज को उनकी भूमि के सांस्कृतिक विरासत का एक नया दृष्टिकोण प्रदान किया। इस समारोह ने लोगों को मिलकर मनाने का सुंदर मौका प्रदान किया और स्थानीय उत्तराखंड की सांस्कृतिक धरोहर को और भी मजबूती से महसूस कराया।
                </p>
            </div>

        </div>
    </div>
      
      <div className='mx-5'>
          <div className="row">
              {/* Left side */}
              <div className="col-md-6 p-3 py-5">
                  <h2>होली मिलन समारोह (मार्च, 2023) </h2>
                  <p>
                      हमारे प्यारे उत्तराखंड समुदाय ने इस होली पर एक आदर्श और अद्वितीय समारोह का आयोजन किया, जिसमें हम ने न केवल रंगों का खेल किया, बल्कि हमारे साथी उत्तराखंडी भाइयों और बहनों के साथ गुड़गांव के एक साथी मानव-समूह में एकत्र होकर आनंद उच्छाटने का भी मौका मिला।
                      <br />
                      <br />
                      हमारे समुदाय के इस समारोह में फ़ोल्क डांसेस की ख़ास धमाल होती है, जो हमारी रिच उत्तराखंडी संस्कृति को दर्शाते हैं। गर्मियों के आगमन के साथ ही, हमने बावर, चोपड़, जागर, फ़िल्मी और अन्य पारंपरिक डांसेस के साथ साथ बच्चों के खिलौने और ख़ास खाने-पीने की भी व्यवस्था की।
                      इस मिलन समारोह का एक और महत्वपूर्ण पहलू था, वो था भाईचारा और सामूहिक एकता की भावना को साझा करना। गुड़गांव के समुदाय में अधिकांश उत्तराखंडी परिवार एक ही स्थान पर आकर बड़े समूह में उपस्थित होते हैं, जो एक अद्वितीय महसूस कराता है।
                      <br />
                      <br />
                      समारोह के दौरान, हमने साथ में बिताए गए समय का आनंद उठाया, और फ़ोल्क डांसेस की झलकियों के साथ हमारी संस्कृति को स्वीकारा। यह समारोह हमें अपनी समुदाय की शक्ति और सामूहिकता का अहसास कराता है, और हम सभी मिलकर एक सजीव और आनंदमय उत्तराखंडी भविष्य की दिशा में कदम बढ़ाते हैं।
                  </p>
              </div>

              {/* Right side */}
              <div className="col-md-6 p-3">
                  <Carousel>
                      <Carousel.Item>
                          <img
                              className="d-block w-100 text-white"
                              // eslint-disable-next-line
                              src="assets\event2\1 (2).jpg"
                              alt="First slide" />
                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100"
                              // eslint-disable-next-line
                              src="assets\event2\1 (3).jpg"
                              alt="Second slide" />
                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100"
                              // eslint-disable-next-line
                              src="assets\event2\1 (4).jpg"
                              alt="Third slide" />
                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100"
                              // eslint-disable-next-line
                              src="assets\event2\1 (8).jpg"
                              alt="Fourth slide" />
                      </Carousel.Item>
                      {/* <Carousel.Item>
        <img
            className="d-block w-100"
            // eslint-disable-next-line
            src="assets\event2\1 (11).jpg"
            alt="Fifth slide" />
    </Carousel.Item> */}
                      <Carousel.Item>
                          <img
                              className="d-block w-100"
                              // eslint-disable-next-line
                              src="assets\event2\1 (14).jpg"
                              alt="Sixth slide" />
                      </Carousel.Item>
                  </Carousel>
              </div>
          </div>
      </div>
      <div className="mx-2">
        <div className="row">
            {/* Left side */}
            <div className="col-md-6 p-5">
        <h2>रक्तदान शिविर (80 यूनिट)</h2>
        <p>
        मई के 28 तारीख को हमारे प्रदेश के एक अद्वितीय रक्तदान शिविर का आयोजन किया गया था, जिसमें हमने समाज के लिए महत्वपूर्ण रक्तदान के माध्यम से नागरिकों के स्वास्थ्य का समर्पण किया। यह आयोजन न केवल विभागीय स्वास्थ्य सेवाओं के लिए एक महत्वपूर्ण योगदान था, बल्कि यह एक सामुदायिक दृष्टिकोण भी रखता है जो हमारे समुदाय के अद्वितीय आदर्शों को प्रकट करता है।
        <br/>
        <br/>
        इस रक्तदान शिविर में कुल 80 यूनिट रक्तदान किए गए थे, जिनसे हमने कई महत्वपूर्ण जीवनों को बचाने में मदद की। इसके साथ ही, हमने रक्तदाताओं की व्यक्तिगत सेवा के लिए आभार व्यक्त किया, जो इस सशक्त प्रयास में हमारे साथ थे।
        रक्तदान का महत्व हम सभी जानते हैं, क्योंकि एक यूनिट रक्त दान से कई जीवनों को नया जीवन मिलता है। यह आयोजन हमारे समुदाय में जागरूकता फैलाने का भी एक माध्यम है, क्योंकि हम अपने स्वास्थ्य से जुड़े जिम्मेदारियों को समझते हैं और उन्हें एक नई दिशा में आगे बढ़ने के लिए प्रोत्साहित करते हैं।
        <br/>
        <br/>
        इस आयोजन के माध्यम से हमने अपने समुदाय के सदस्यों को साझा दिलाया कि हम सभी एक-दूसरे की चिंता करते हैं और एक दूसरे के साथ आपासी सहयोग और समर्थन करते हैं। यह एक मानविक संदेश है जो हमारे समुदाय के सामाजिक और सामुदायिक विकास के प्रति हमारे प्रतिबद्धता को दर्शाता है।
        </p>
        </div>

            {/* Right side */}
            <div className="col-md-6 p-3">
                <div className="row py-2">
                    <div className="col-12">
                        <img
                            src="assets/images/1 (11).jpg"
                            alt=""
                            style={imageSize} />
                    </div>
                </div>
            </div>
        </div>
    </div></>
  );
}
