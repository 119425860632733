import React, { useState } from 'react';

const UCSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = 4; // Set the total number of slides

  // Custom text content for each slide
  const slideText = [
    "माता के लिए 'जागरण' - 21 अक्टूबर, 2023 को उत्तराखंड सांस्कृतिक एकता मंच द्वारा एक विशेष जागरण कार्यक्रम का आयोजन किया जा रहा है। इस जागरण के दौरान हम माता की पूजा और भजनों के साथ एक आध्यात्मिक माहौल में एकत्र आएंगे। यह एक सामुदायिक आयोजन है जो हमारे समुदाय के सदस्यों को आपसी मिलनसर में आने का अवसर प्रदान करेगा",
    "दशहरा का पर्व 24 अक्टूबर, 2023 को मनाया जाएगा। यह पर्व बुराई पर अच्छाई की जीत का प्रतीक है। इस दिन भगवान राम ने रावण का वध किया था। दशहरा के दिन जगह-जगह रावण, मेघनाथ और कुंभकर्ण के पुतले जलाए जाते हैं। ",
    "दिवाली का पर्व प्रकाश का पर्व है। इस दिन भगवान राम चौदह वर्ष का वनवास काटकर अयोध्या लौटे थे। उनके स्वागत में अयोध्या के लोगों ने दीप जलाए थे। तब से दिवाली का पर्व मनाया जाता है। दिवाली के दिन लोग अपने घरों को सजाते हैं, दीये जलाते हैं और पटाखे फोड़ते हैं। इस दिन लोग मिठाईयाँ बाँटते हैं और दोस्तों और परिवार के सदस्यों के साथ मिलकर जश्न मनाते हैं। दिवाली की हार्दिक शुभकामनाएं!",
    "कार्तिक माह के शुक्ल पक्ष की एकादशी को देवउठनी एकादशी कहा जाता है। इस दिन भगवान विष्णु चार महीने की योग निद्रा से जागते हैं। इस दिन से ही मांगलिक कार्य शुरू हो जाते हैं। देवउठनी एकादशी के दिन लोग उपवास रखते हैं और भगवान विष्णु की पूजा करते हैं। इस दिन तुलसी विवाह भी किया जाता है। देवउठनी एकादशी की हार्दिक शुभकामनाएं!",
  ];
  // Upcoming Events Headings
  const slideHeading = [
    "जागरण - 21 अक्टूबर, 2023",
    "दशहरा का पर्व (24 अक्टूबर, 2023)",
    "दिवाली 12 नवंबर, 2023",
    "देवउठनी एकादशी (24 नवंबर, 2023)",
  ]

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide === totalSlides ? 1 : prevSlide + 1));
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 1 ? totalSlides : prevSlide - 1));
  };

  return (
    <div className="slider-container p-0">
      {/* Render the image and text based on the current slide */}
      <div
        className={`slider-slide slide-${currentSlide} ${
          currentSlide % 2 === 0 ? 'even-slide' : 'odd-slide'
        }`}
      >
        <img
          src={`assets/images/img-${currentSlide}.jpeg`}
          alt={`Slide ${currentSlide}`}
        />
        <div className="text-container col-md-6">
        <h1 className='pb-5 fw-bold'>अपडेट्स</h1>
          <h3>{slideHeading[currentSlide - 1]}</h3>
          <p>{slideText[currentSlide - 1]}</p>
        </div>
      </div>

      {/* Next and Prev buttons */}
      <div className="slider-controls">
        <button onClick={handlePrev}>&lt;</button>
        <button onClick={handleNext}>&gt;</button>
      </div>
    </div>
  );
};

export default UCSlider;